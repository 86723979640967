import React from "react";
import "./App.css";
import { motion, Variants } from "framer-motion";

export const App: React.FC = () => {
  const icon: Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      fill: "rgba(255, 255, 255, 1)",
      transition: {
        default: { duration: 2, ease: "easeInOut" },
        fill: { duration: 2, ease: [1, 0, 0.8, 1] }
      }
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1155.01 345.21"
          style={{ maxWidth: "720px", margin: "0 auto" }}
          variants={{ hidden: {}, visible: {} }}
          transition={{ staggerChildren: 0.1 }}
          initial="hidden"
          animate="visible"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <motion.path
                variants={icon}
                className="cls-1"
                d="M109.8 245.01 108.84 245.01 75.27 4.79 0 4.79 0 340.42 46.03 340.42 46.03 86.3 46.98 86.3 85.34 340.42 129.46 340.42 167.81 86.3 168.77 86.3 168.77 340.42 218.64 340.42 218.64 4.79 143.36 4.79 109.8 245.01z"
              />
              <motion.path
                variants={icon}
                className="cls-1"
                d="M310.69 4.79 257.95 4.79 257.95 340.42 397.47 340.42 397.47 292.47 310.69 292.47 310.69 4.79z"
              />
              <motion.path
                variants={icon}
                className="cls-1"
                d="M541.31 205.69 540.35 205.69 489.05 4.79 422.88 4.79 422.88 340.42 469.87 340.42 469.87 95.41 470.83 95.41 534.11 340.42 588.29 340.42 588.29 4.79 541.31 4.79 541.31 205.69z"
              />
              <motion.path
                variants={icon}
                className="cls-1"
                d="M703.84,0Q665,0,644.39,22.06T623.77,84.39V260.83q0,40.28,20.62,62.33t59.45,22q38.83,0,59.46-22t20.61-62.33V84.39q0-40.29-20.61-62.33T703.84,0Zm27.33,264.18q0,33.09-27.33,33.09t-27.33-33.09V81Q676.51,48,703.84,48T731.17,81Z"
              />
              <motion.path
                variants={icon}
                className="cls-1"
                d="M927.75 4.79 893.23 265.15 892.27 265.15 857.75 4.79 804.53 4.79 855.35 340.42 925.35 340.42 976.18 4.79 927.75 4.79z"
              />
              <motion.path
                variants={icon}
                className="cls-1"
                d="M1100.35,4.79h-71.44L974.24,340.42h48.92l9.59-65.69h59.93v-1l9.59,66.64H1155ZM1039,229.18l23-165.89h1l23.49,165.89Z"
              />
            </g>
          </g>
        </motion.svg>
      </header>
    </div>
  );
};

export default App;
